import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import './assets/css/index.css';
import { BrowserRouter } from 'react-router-dom';
import { defaults } from 'react-sweet-state';
import LoadingRouter from 'components/loadingRouter';
createBrowserHistory();
defaults.batchUpdates = true;

const App = lazy(() => import(/*webpackChunkName: 'app' */ './routes/routes'));

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<LoadingRouter />}>
      <App />
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root'),
);
