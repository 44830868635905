import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 2,
    color: '#fff',
    backgroundColor: '#102d39',
  },
}));

interface Props {
  open?: boolean;
}

const LoadingRouter: React.FC<Props> = ({ open = true }: Props) => {
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
export default LoadingRouter;
